import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
// import IconButton from "@material-ui/core/IconButton";
// import RoomIcon from "@material-ui/icons/Room";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import CloseIcon from "@material-ui/icons/Close";
import CardActionArea from "@material-ui/core/CardActionArea";
import "./Unidades.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  media: {
    height: "200px",
    width: "100%",
  },
  textoModal: {
    marginLeft: "0.5rem",
  },

  button: {
    marginTop: ".75rem",
    width: "43px",
  },
  cardModal: {
    width: "35rem",
    [theme.breakpoints.down(800)]: {
      width: "25rem",
    },
    [theme.breakpoints.down(600)]: {
      width: "22rem",
    },
    [theme.breakpoints.down(480)]: {
      width: "20rem",
    },
  },
}));

export default function Unidades(props) {
  const classes = useStyles();
  const [abrir, setAbrir] = useState(false);
  const mapaUnidade = props.unidade;
  const mapaEndereco = props.endereco;

  const abrirModal = () => {
    setAbrir(true);
  };
  const fecharModal = () => {
    setAbrir(false);
  };

  const abrirLink = () => {
    window.open(`https://www.google.com/maps/place/${mapaEndereco}`);
  };

  const corpoDoModal = (
    <div className="modal-body">
      <CloseIcon
        style={{ color: "white" }}
        className="modal-body-close"
        onClick={fecharModal}
      />
      <header>
        <h1>{props.unidade}</h1>
      </header>
      <div className="modal-content">
        <div className="content-block">
          <h2>Endereço</h2>
          <p>{props.endereco}</p>
        </div>
        {props.pontoreferencia && (
          <div className="content-block">
            <h2>Ponto de referência</h2>
            <p>{props.pontoreferencia}</p>
          </div>
        )}
        <div className="content-block">
          <h2>Horário de atendimento</h2>
          <p>{props.atendimento}</p>
        </div>
        <div className="content-block">
          <h2>Telefone</h2>
          <p>{props.telefone}</p>
        </div>
        <div className="content-block">
          <h2>Kids</h2>
          <p>{props.kids}</p>
        </div>
        {props.horariocoleta && (
          <div className="content-block">
            <h2>Horário de coleta</h2>
            <p>{props.horariocoleta}</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className="listaDiv">
        <Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "200px", width: "100%" }}
          >
            <CardActionArea onClick={abrirLink}>
              <CardMedia
                component="img"
                className={classes.media}
                src={props.foto}
                title="Dna Foto"
              />
            </CardActionArea>
          </Grid>
          <Grid>
            <div style={{ display: "flex" }}>
              {/*width: "calc(100% - 43px)"*/}
              <div style={{ padding: ".75rem" }}>
                <div className="listaTexto">
                  <h1>{mapaUnidade}</h1>
                  <p>{mapaEndereco}</p>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: ".5rem",
                    left: ".75rem",
                  }}
                >
                  <div>
                    <Button
                      size="small"
                      color="primary"
                      onClick={abrirModal}
                      style={{ padding: 0 }}
                    >
                      Mais informações
                    </Button>
                    <Modal
                      BackdropProps={{
                        style: { background: "rgba(0, 0, 0, 0)" },
                      }}
                      open={abrir}
                      onClose={fecharModal}
                      className={classes.modal}
                    >
                      {corpoDoModal}
                    </Modal>
                  </div>
                </div>
              </div>

              {/*<div className={classes.button}>
               <IconButton
                  onClick={abrirLink}
                  style={{ padding: 0, paddingRight: ".5rem" }}
                >               
                  <RoomIcon
                    fontSize="large"
                    style={{ color: "rgb(221,75,62)" }}
               />
                </IconButton>
                
              </div>
              */}
            </div>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
