import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackgroundContainer from "../../../components/BackgroundContainer/BackgroundContainer";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  Input,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import "./styleRelatorio.css";
import api from "../../../servicos/api";
import SelectSetor from "../components/setores";
import SelectUnidade from "../components/unidade";

export default function Relatorio() {
  const [arquivoLista, setArquivoLista] = useState([]);
  const [acontecendo, setAcontecendo] = useState("");
  const [ehFuncionario, setehFuncionario] = useState(false);
  const [unidadeId, setUnidadeId] = useState("");
  const [setorId, setSetorId] = useState("");
  const [qmPresenciou, setQmPresenciou] = useState("");
  const [qmCometeu, setQmCometeu] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [anonimo, setanonimo] = useState(false);
  const [qtTempo, setQtTempo] = useState("");
  const navigate = useNavigate();

  const linkStyle = {
    textDecoration: "none",
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const telefoneMascara = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);
    try {
      const formData = new FormData();

      formData.append("acontecendo", acontecendo);
      formData.append("ehFuncionario", ehFuncionario);
      formData.append("unidadeId", unidadeId);
      formData.append("setorId", setorId);
      formData.append("qmPresenciou", qmPresenciou);
      formData.append("qmCometeu", qmCometeu);
      formData.append("qtTempo", qtTempo);
      formData.append("nome", nome);
      formData.append("celular", celular);
      formData.append("email", email);
      formData.append("anonimo", anonimo);

      Array.from(arquivoLista).forEach((f) => formData.append("provas", f));

      console.log(formData);

      const reqpost = await api.post("/v2/gestaoEtica/denuncia", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/canaldaetica/relatorio/confirmacao", {
        state: { id: reqpost.data.data.id },
      });

      console.log(reqpost);
      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <BackgroundContainer>
      <div className="all-page">
        <header className="cabecalho">
          <h1 className="titulo">Cadastro de denúncia</h1>
        </header>
        <main className="principal-rel">
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item xl={12}>
                <TextField
                  onChange={(e) => {
                    setAcontecendo(e.target.value);
                  }}
                  // {...register("acontecendo", { required: true })}
                  fullWidth
                  required
                  multiline
                  variant="outlined"
                  id="outlined-basic"
                  label="O que pode estar acontecendo?"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <h3>
                  Por favor, responda às seguintes perguntas, mesmo se já
                  respondeu acima:
                </h3>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-trabalha"
                        style={{
                          margin: "0",
                          marginLeft: "0.8em",
                          top: "-5%",
                        }}
                        required
                      >
                        Você trabalha na DNA Center?
                      </InputLabel>
                      <Select
                        required
                        labelId="select-trabalha"
                        value={ehFuncionario}
                        onChange={(e) => {
                          setehFuncionario(e.target.value);
                        }}
                        // {...register("ehFuncionario", { required: true })}
                        fullWidth
                        variant="outlined"
                        label="Você trabalha na DNA Center?"
                      >
                        <MenuItem fullWidth value={true}>
                          Sim
                        </MenuItem>
                        <MenuItem fullWidth value={false}>
                          Não
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <SelectSetor
                      onChange={(e) => {
                        setSetorId(e.target.value);
                      }}
                      // {...register("setorId", {required: true})}
                      value={setorId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <SelectUnidade
                      onChange={(e) => {
                        setUnidadeId(e.target.value);
                      }}
                      // {...register("unidadeId", {required: true})}
                      value={unidadeId}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setQmPresenciou(e.target.value);
                  }}
                  // {...register("qmPresenciou", {required: true})}
                  required
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  label="Quem presencia ou presenciou?"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setQmCometeu(e.target.value);
                  }}
                  // {...register("qmCometeu", {required: true})}
                  required
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  label="Quem comete ou cometeu?"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setQtTempo(e.target.value);
                  }}
                  required
                  // {...register("qtTempo", { required: true })}
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  label="Há quanto tempo isso vem acontecendo?"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="file"
                  inputProps={{
                    multiple: true,
                    accept:
                      "application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpg, image/jpeg",
                  }}
                  color="primary"
                  onChange={(e) => {
                    setArquivoLista(e.target.files);
                  }}
                  // {...register("acontecendo", { required: true })}
                >
                  Anexar arquivoLista
                </Input>
              </Grid>
              <Grid item xs={12}>
                <h3>
                  Preencha os seus dados de contato para que possamos contatá-lo
                  se necessário:
                </h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  label="Nome"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      id="outlined-basic"
                      label="Email"
                    ></TextField>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onKeyUp={(e) => {
                        const input = e.target;
                        input.value = telefoneMascara(input.value);
                      }}
                      onChange={(e) => {
                        setCelular(e.target.value);
                        console.log(celular);
                      }}
                      fullWidth
                      variant="outlined"
                      id="outlined-basic"
                      label="Celular"
                    ></TextField>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="select-trabalha"
                    style={{
                      margin: "0",
                      marginLeft: "0.8em",
                      top: "-5%",
                    }}
                  >
                    Deseja manter anonimato?
                  </InputLabel>
                  <Select
                    value={anonimo}
                    onChange={(e) => {
                      e.preventDefault();
                      setanonimo(e.target.value);
                    }}
                    fullWidth
                    required
                    variant="outlined"
                    id="outlined-basic"
                    label="Deseja manter anonimo?"
                  >
                    <MenuItem fullWidth value={true}>
                      Sim
                    </MenuItem>
                    <MenuItem fullWidth value={false}>
                      Não
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid container item xs={12} justifyContent="space-between">
                <Link to="/canaldaetica" style={linkStyle}>
                  <Button variant="contained" color="secondary" type="button">
                    Cancelar
                  </Button>
                </Link>

                <Button variant="contained" color="primary" type="submit">
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </main>
      </div>
    </BackgroundContainer>
  );
}
