import React, { useState, useEffect } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useSnackbar } from "notistack";
import api from "../../../servicos/api";

export default function SelectSetor({
  onChange,
  value,
  disabled = false,
  required = false,
}) {
  const [setores, setSetores] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaSetores() {
      try {
        const getSetores = await api.get("/v2/arh/setores");
        const setoresArray = getSetores.data.setores;
        setoresArray.unshift({ id: "", nome: "Todos" });
        setSetores(setoresArray);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar os setores.", {
          variant: "error",
        });
      }
    }
    pegaSetores();
  }, [enqueueSnackbar]);

  return (
    <FormControl fullWidth>
      <InputLabel
        id="setores"
        style={{
          margin: "0",
          marginLeft: "0.8em",
          top: "-5%",
        }}
      >
        Em que setor ocorre ou ocorreu?
      </InputLabel>
      <Select
        root
        labelId="setores"
        value={value}
        onChange={onChange}
        disabled={disabled}
        required
        fullWidth
        variant="outlined"
        // id="outlined-basic"

        label="Você trabalha na DNA Center?"
      >
        {setores.map((setor) => {
          return (
            <MenuItem
              className="menu-item-select-setor"
              key={setor.id}
              fullWidth
              value={setor.id}
            >
              {setor.nome}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
