const nomePorCampo = {
  bairro: "Bairro",
  celular: "Celular",
  cep: "CEP",
  cidade: "Cidade",
  cpf: "CPF",
  data_nascimento: "Data de nascimento",
  endereco: "Logradouro",
  endereco_numero: "Número",
  nome_completo: "Nome completo",
  profissional_saude: "Profissional de saúde?",
  tipo_amostra: "Tipo de amostra?",
  estado: "Estado",
  sexo: "Sexo",
};

export default nomePorCampo;
