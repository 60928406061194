import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Agenda from "./paginas/Agenda";
import Confirmacao from "./paginas/EsusForm/Confirmacao/Confirmacao";
import Formulario from "./paginas/Formulario";
import Invalido from "./paginas/EsusForm/Invalido";
import MenuExame from "./paginas/Exames/MenuExame";
import Unidades from "./paginas/Unidades";
import PortalPrivacidade from "./paginas/PortalPrivacidade";
import CanalEtica from "./paginas/CanalEtica";
import "./App.css";
import Relatorio from "./paginas/CanalEtica/Relatorio";
import ConfirmacaoEnvioRel from "./paginas/CanalEtica/ConfirmaRelatorio";
//teste
function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Routes>
          <Route path="/portalPrivacidade" element={<PortalPrivacidade />} />

          <Route path="/canaldaetica" element={<CanalEtica />} />

          <Route path="/canaldaetica/relatorio" element={<Relatorio />} />

          <Route
            path="/canaldaetica/relatorio/confirmacao"
            element={<ConfirmacaoEnvioRel />}
          />

          <Route path="/unidades" element={<Unidades />} />

          <Route path="/agenda" element={<Agenda />} />

          <Route
            path="/formularioCovid/confirmacao"
            element={<Confirmacao />}
          />

          <Route path="/formularioCovid/invalido" element={<Invalido />} />

          <Route path="/formularioCovid/:token?" element={<Formulario />} />

          <Route path="/" element={<MenuExame />} />
        </Routes>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
