import React, { useState, useEffect } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { useSnackbar } from "notistack";
import api from "../../../servicos/api";

export default function SelectUnidade({
  onChange,
  value,
  required = false,
  disabled = false,
}) {
  const [unidades, setUnidades] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function pegaUnidades() {
      try {
        const arrayUnidades = await api.get("/v2/geral/unidade");
        const unidadesArray = arrayUnidades.data.unidade;
        unidadesArray.unshift({ id: "", nome: "Todos" });
        setUnidades(unidadesArray);
      } catch (error) {
        enqueueSnackbar("Erro! Não foi possível buscar as unidades.", {
          variant: "error",
        });
      }
    }

    pegaUnidades();
  }, [enqueueSnackbar]);

  return (
    <FormControl fullWidth>
      <InputLabel
        id="select-unidade"
        style={{
          margin: "0",
          marginLeft: "0.8em",
          top: "-5%",
        }}
      >
        Em que unidade ocorre ou ocorreu?
      </InputLabel>
      <Select
        labelId="select-unidade"
        value={value}
        onChange={onChange}
        disabled={disabled}
        required
        fullWidth
        variant="outlined"
        id="outlined-basic"
        label="Em que unidade ocorre ou ocorreu?"
      >
        {unidades.map((unidade) => {
          return (
            <MenuItem key={unidade.id} fullWidth value={unidade.id}>
              {unidade.nome}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
