/* This function already assumes that this is a string with 11 characters */

function validateEqualDigits(cpf) {
  const char = cpf[0];
  for (let i = 1; i < cpf.length; i++) {
    if (cpf[i] !== char) {
      return true;
    }
  }
  return false;
}

function validateDigit(cpf, limit, digit) {
  let sum = 0,
    remainder;
  for (let i = 0; i < limit; i++) {
    sum += parseInt(cpf[i], 10) * (limit + 1 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10) {
    remainder = 0;
  }
  return remainder === digit;
}

export default function validateCPF(cpf) {
  const firstVerifierDigit = parseInt(cpf[9], 10);
  const secondVerifierDigit = parseInt(cpf[10], 10);

  return (
    validateDigit(cpf, 9, firstVerifierDigit) &&
    validateDigit(cpf, 10, secondVerifierDigit) &&
    validateEqualDigits(cpf)
  );
}
