import React from "react";
import { Link } from "react-router-dom";
import manualPdf from "../../assets/pdfs/manual_de_etica_e_conduta.pdf";
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Paper,
  Container,
} from "@material-ui/core";
import api from "../../servicos/api";

import "./styles.css";
import { useState } from "react";

export default function CanalEtica() {
  const [codigoPesquisa, setCodigoPesquisa] = useState("");
  const [conteudo, setConteudo] = useState("");
  const [situacao, setSituacao] = useState("");
  const [resposta, setResposta] = useState("");
  // eslint-disable-next-line
  const [procede, setProcede] = useState("");

  const linkStyle = {
    textDecoration: "none",
  };

  const handleClick = async () => {
    try {
      const req = await api.get(`/v2/gestaoEtica/denuncia/${codigoPesquisa}`);
      setConteudo(req.data.data.response.acontecendo);
      setSituacao(req.data.data.response.situacao);
      setResposta(req.data.data.response.resposta);
      setProcede(req.data.data.response.denunciaProcede);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="background-container">
      <Container xl>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <h1
              style={{
                fontSize: "2.5rem",
                textAlign: "center",
                backgroundColor: "#91a1fa",
                color: "#232424",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              CANAL DE ÉTICA
            </h1>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={2}
            alignContent="space-around"
            xs={12}
            item
            style={{ marginLeft: "1rem" }}
          >
            <Grid item xl={6} md={6} sm={11} xs={11}>
              <Paper style={{ padding: "1rem" }} elevation={0}>
                <h3>BEM-VINDO AO CANAL DE ÉTICA DO DNA CENTER</h3>
                {/* <Paper elevation={3}> */}
                <Card style={{ margin: "1rem" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Sobre a ética no DNA Center
                    </Typography>
                    <br></br>
                    <Typography variant="body2">
                      No DNA Center nosso compromisso com as práticas éticas e
                      os relacionamentos honestos é uma importante razão para
                      que os funcionários, clientes, fornecedores e outras
                      partes interessadas escolham trabalhar conosco. Nossos
                      princípios definem como nossos colaboradores do DNA Center
                      se comportam. Eles delineiam as características que nossos
                      clientes e outras partes interessadas podem esperar de
                      nós, e nosso{" "}
                      <a href={manualPdf} style={{ textDecoration: "none" }}>
                        Manual de Ética e Conduta
                      </a>{" "}
                      estabelece os padrões comportamentais esperados de todos
                      nós. Sentimos orgulho em fazer a coisa certa.
                    </Typography>
                  </CardContent>
                </Card>
                {/* </Paper> */}

                <Card style={{ margin: "1rem" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Disponibilidade
                    </Typography>
                    <br></br>
                    <Typography variant="body2">
                      O Canal de Ética do DNA Center está disponível a todos os
                      funcionários como uma forma de comunicar preocupações ou
                      qualquer coisa que você veja ou suspeite que constitua:
                      <br />• Comportamento antiético/ilícito;
                      <br /> • Conflitos de interesses;
                      <br /> • Assédio moral/sexual;
                      <br /> • Relacionamento com o cliente;
                      <br /> • Comunicação e uso da imagem da empresa;
                      <br /> • Saúde e Segurança Ocupacional;
                      <br /> • Preservação de recursos.
                    </Typography>
                  </CardContent>
                </Card>

                <Card style={{ margin: "1rem" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Como comunicar uma preocupação
                    </Typography>
                    <br></br>
                    <Typography variant="body2">
                      Entenda melhor o processo de comunicar uma preocupação:
                      <br />
                      • Ao expor uma preocupação você receberá informações sobre
                      a possibilidade de se manter anônimo.
                      <br />
                      • As informações fornecidas por você serão enviadas ao
                      Comitê de Ética, composto por representantes da
                      Administração, GP, Jurídico e LGPD.
                      <br />
                      • O Comitê de Ética determinará a ação adequada e, na
                      maioria dos casos, submeterá a questão para investigação
                      pela área pertinente.
                      <br />
                      • Todos os casos são comunicados ao Conselho de Direção do
                      DNA Center.
                      <br />
                      • As denúncias são tratadas com rapidez e discrição.
                      <br />
                      <br />
                      Após concluir a denúncia, você receberá um código
                      exclusivo chamado “Protocolo”. Anote o código e mantenha-o
                      em um local seguro. Após 10 (dez) dias úteis, utilize o
                      código para checar a denúncia em relação a feedback ou
                      perguntas.
                    </Typography>
                  </CardContent>
                </Card>

                <Card style={{ margin: "1rem" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Este não é um serviço de emergência
                    </Typography>
                    <br></br>
                    <Typography variant="body2">
                      Não utilize este site para comunicar eventos que
                      apresentem uma ameaça imediata à vida ou propriedade. As
                      denúncias apresentadas por meio deste serviço poderão não
                      receber uma resposta imediata. Caso você necessite de
                      assistência de emergência, entre em contato com as
                      autoridades locais.
                    </Typography>
                  </CardContent>
                </Card>

                <div className="botao-denuncia">
                  <Link style={linkStyle} to="/canaldaetica/relatorio">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ right: "0" }}
                    >
                      Fazer Denúncia
                    </Button>
                  </Link>
                </div>
                <div>
                  <br />
                  <p className="link-lei">
                    <a
                      href={manualPdf}
                      style={{ textDecoration: "none", fontSize: "small" }}
                    >
                      <span role="img" aria-label="img-warning">
                        ⚠️
                      </span>{" "}
                      Clique aqui para baixar nosso Manual de Ética e Conduta.
                    </a>
                  </p>
                  <br />
                  <p className="link-lei">
                    <a
                      href="http://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2022/Lei/L14457.htm"
                      style={{ textDecoration: "none", fontSize: "small" }}
                    >
                      Veja a lei que determina a implantação do Canal de Ética
                      nas empresas com CIPA.
                    </a>
                  </p>
                </div>
              </Paper>
            </Grid>

            {/* <Divider orientation="vertical" flexItem /> */}
            <Grid item xl={5} md={5} sm={11} xs={11}>
              <Paper
                elevation={4}
                style={{ margin: "0.8rem", padding: "1.2rem", width: "100%" }}
              >
                <h3>Já fez uma denúncia? </h3>

                <label style={{ fontSize: "small" }}>
                  Insira o código recebido abaixo e clique em buscar para
                  acompanhar seu caso
                </label>

                <form className="formulario-acompanhamento">
                  <Grid
                    container
                    direction="column"
                    style={{
                      padding: "0rem, 0.5rem, 0.5rem, 0rem",
                      marginRight: "0.5rem",
                    }}
                  >
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Código"
                      placeholder="insira seu código para acompanhamento aqui"
                      value={codigoPesquisa}
                      onChange={(e) => setCodigoPesquisa(e.target.value)}
                    />

                    <TextField
                      multiline
                      id="outlined-basic"
                      label="Conteúdo da denúncia"
                      value={conteudo}
                    />
                    <TextField
                      value={situacao}
                      multiline
                      id="outlined-basic"
                      label="Situação da denúncia"
                    />
                    <TextField
                      value={resposta}
                      multiline
                      id="outlined-basic"
                      label="Resposta da denúncia"
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClick}
                      style={{
                        marginTop: "0.5rem",
                        width: "35%",
                        height: "40px",
                        fontSize: "0.8rem",
                      }}
                    >
                      Buscar código
                    </Button>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
