import React, { useState, useEffect } from 'react';
import DetalheExame from './DetalheExame';
import './MenuExame.css'
import api from '../../servicos/api';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery, TextField, Container} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

//O menu exame oferece a opção de pesquisa por parte do nome do exame, mostrando uma lista com todos os exames
function MenuExame() {

  const [idExame, setIdExame] = React.useState('');
  const [exames, setExames] = useState([]);
  const [nome, setNome] = useState('');
  const [open, setOpen] = React.useState(false);
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = (id) => {
    setIdExame(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Carrega todos os exames na página sem a utilização do filtro de nome
  useEffect(() => {
    async function carregarExames() {
      const response = await api.get('/api/exames');
      setExames(response.data);
    }
    carregarExames();
  }, []);

  //Atualiza a página de acordo com o filtro solicitado
  async function procurarExames(e) {
    e.preventDefault();
    setNome(e.target.value.toUpperCase());
    const aux = e.target.value.toUpperCase();
    const response = await api.get('/api/exames/' + aux, {
      nome
    })

    setExames(response.data);
    console.log(exames);
  }

  return (
    
    <Container maxWidth="lg">
      <div>
          <div className="pesquisa">
            <TextField 
              label="Pesquisar"
              placeholder="Informe o nome do exame"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={procurarExames}
              value={nome}
            />
          </div>

          <div className="groupItem">
              {exames.map(exame => (
                <div key={exame.id} className="item" onClick={() => handleClickOpen(exame.id)}>
                  {exame.nome}
                </div>
              ))}
              
              { exames.length === 0
                ?<div className="itemVazio">
                  Nenhum exame encontrado...
                </div> : ''
            }
          </div>

          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
          >
            <DialogContent>
              <DialogContentText>
                <DetalheExame id={idExame} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Voltar
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    </Container>
  );
}

export default MenuExame;