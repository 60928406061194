import React from "react";
import {
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import StyledRadio from "../StyledRadio";
import "./styles.css";

export default function LinhaFormulario({ label, onChange, value, disabled }) {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      className="section-inner linha-formulario"
    >
      <Grid item xs={6} className="form-radio-question">
        <FormLabel component="legend">{label}</FormLabel>
      </Grid>
      <Grid item xs={6}>
        <RadioGroup
          value={value}
          row
          style={{ justifyContent: "flex-end" }}
          onChange={onChange}
        >
          <FormControlLabel
            value="Nao"
            control={<StyledRadio />}
            label="Não"
            disabled={disabled}
          />
          <FormControlLabel
            value="Sim"
            control={<StyledRadio />}
            label="Sim"
            disabled={disabled}
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
