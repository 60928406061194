import React from "react";
import DNACenterLogo from "../../assets/images/logo-dna-center.png";

import "./styles.css";

export default function Feedback({ title, text }) {
  return (
    <div className="confirmation-card">
      <div>
        <h1 style={{ marginBottom: "1rem" }}>{title}</h1>
        <p>{text}</p>
      </div>
      <img
        src={DNACenterLogo}
        alt="Logo do DNA Center"
        style={{ width: "240px", height: "140px", alignSelf: "center" }}
      />
    </div>
  );
}
