function CargosProfissionalSaude() {
  let array = [
    "225 - Médico",
    "2212 - Biomédicos",
    "2234 - Farmacêuticos",
    "2232 - Cirurgiões-dentistas",
    "2236 - Fisioterapeutas",
    "2238 - Fonoaudiólogos",
    "2515 - Psicólogos e psicanalistas",
    "2237 - Nutricionistas",
    "3222 - Técnicos e auxiliares de enfermagem",
    "2235 - Enfermeiros e afins",
    "3242 - Técnicos de laboratórios de saúde e bancos de sangue",
    "5199 - Outros trabalhadores dos serviços",
  ];
  const cargos = array.sort(function (a, b) {
    return a.split(" - ")[1] < b.split(" - ")[1]
      ? -1
      : a.split(" - ")[1] > b.split(" - ")[1]
      ? 1
      : 0;
  });
  return cargos;
}

export default CargosProfissionalSaude;
