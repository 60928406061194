export default function replaceAll(str, char, newChar) {
  let newStr = "";
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== char) {
      newStr += str[i];
    } else {
      newStr += newChar;
    }
  }
  return newStr;
}
