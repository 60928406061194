import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

export default function InputDate(props) {
  return (
    <Grid container style={{ marginBottom: "1rem" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DatePicker
          maxDate={dayjs(new Date())}
          label={props.text}
          value={dayjs(props.value)}
          onChange={(value) => {
            props.acao(value);
          }}
          // KeyboardButtonProps={{
          //   "aria-label": "mudar data",
          // }}
        />
      </LocalizationProvider>
    </Grid>
  );
}
