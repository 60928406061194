import { withStyles, Radio } from "@material-ui/core";

const StyledRadio = withStyles({
  root: {
    color: "#003399",
    "&$checked": {
      color: "#003399",
    },
  },
  checked: {
    color: "#003399",
  },
})(Radio);

export default StyledRadio;
