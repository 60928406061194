import nomePorCampo from "../../utils/nomePorCampo";
import { Checkbox, withStyles } from "@material-ui/core";
import construirData from "../../utils/construirData";

function replaceAll(str, replaced, replacement) {
  return str.split(replaced).join(replacement);
}

export const nomePorSintoma = {
  dor_garganta: "DOR DE GARGANTA",
  dispneia: "DIFICULDADE DE RESPIRAR",
  febre: "FEBRE",
  tosse: "TOSSE",
  dor_cabeca: "DOR DE CABEÇA",
  disturbios_gustativos: "PERDA DO PALADAR",
  disturbios_olfativos: "PERDA DO OLFATO",
  coriza: "CORIZA (NARIZ ESCORRENDO)",
  assintomatico: "ASSINTOMÁTICO",
};

export const nomePorCondicao = {
  doenca_resp: "DOENÇA RESPIRATÓRIA CRÔNICA DESCOMPENSADA",
  doenca_card: "DOENÇA CARDÍACA CRÔNICA",
  diabetes: "DIABETES",
  doenca_renal: "DOENÇA RENAL CRÔNICA OU EM ESTÁGIO AVANÇADO",
  imunossupressao: "IMUNOSSUPRESSÃO",
  gestante: "GESTANTE",
  doenca_cromo: "DOENÇA CROMOSSÔMICA OU EM ESTADO DE FRAGILIDADE",
  puerpera: "PUÉRPERA (45 DIAS APÓS O PARTO)",
  obesidade: "OBESIDADE",
  positivo_covid: "JÁ TESTOU POSITIVO ANTERIORMENTE PARA O COVID 19",
};
export const StyledCheckbox = withStyles({
  root: {
    width: 20,
    height: 20,
    borderRadius: 4,
    color: "#b7b7b7",
    marginRight: "8px",
    "&$checked": {
      color: "#003399",
    },
  },
  checked: {
    backgroundColor: "white",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      backgroundSize: "20px 20px",
      color: "black",
    },
  },
})(Checkbox);

const validacoesPorCampo = {
  profissional_saude: ["nao vazio"],
  nome_completo: ["nao vazio", "somente letras"],
  data_nascimento: ["nao vazio", "formato data", "data maxima"],
  cpf: ["nao vazio", "formato cpf"],
  sexo: ["nao vazio", "formato sexo"],
  celular: ["nao vazio", "formato celular"],
  cep: ["nao vazio", "formato cep"],
  endereco: ["nao vazio"],
  endereco_numero: ["nao vazio"],
  bairro: ["nao vazio"],
  cidade: ["nao vazio"],
  estado: ["nao vazio", "formato estado"],
};

const validacoes = {
  "nao vazio": {
    validar: (valor) => valor !== null && valor.length > 0,
    mensagem: (campo) => `O campo ${campo} não pode ser vazio.`,
  },
  "somente letras": {
    validar: (valor) => valor.trim().match(/^[a-zA-ZÀ-û\s'´`]*$/),
    mensagem: (campo) => `O campo ${campo} só pode ter letras.`,
  },
  "formato data": {
    validar: (valor) => {
      return valor.match(
        /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[- /.](19|20)\d\d$/
      );
    },
    mensagem: (campo) => `Data inválida.`,
  },
  "formato cpf": {
    validar: (valor) => {
      let cpfValido = false;
      let soma = 0;
      let resto = 0;

      const cpf = replaceAll(replaceAll(valor, "-", ""), ".", "");

      const noveDigitos = cpf.substring(0, 9);
      const dezDigitos = cpf.substring(0, 10);
      const digitosVerificadores = cpf.substring(9);

      for (let i = 0; i < 9; i++) {
        soma += (11 - (i + 1)) * parseInt(noveDigitos[i]);
      }

      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(digitosVerificadores[0])) {
        return false;
      }

      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += (12 - (i + 1)) * parseInt(dezDigitos[i]);
      }

      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(digitosVerificadores[1])) {
        return false;
      }

      cpfValido = true;

      return cpf.match(/^\d{11}$/) && cpfValido;
    },
    mensagem: (campo) => `CPF inválido.`,
  },
  "formato sexo": {
    validar: (valor) => valor === "M" || valor === "F",
    mensagem: (campo) => `Sexo inválido.`,
  },
  "formato celular": {
    validar: (valor) => {
      let numero;

      if (valor.replaceAll) {
        numero = valor
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .trim();
      } else {
        numero = valor
          .split("(")
          .join("")
          .split(")")
          .join("")
          .split("-")
          .join("")
          .trim();
      }
      return numero.match(
        /^(1[1-9]|21|22|24|27|28|3[1-8]|4[1-9]|51|53|54|55|6[1-9]|71|7[3-5]|77|79|8[1-9]|9[1-9])9\d{8}$/
      );
    },
    mensagem: (campo) =>
      `Celular inválido. O número deve ser o ddd, acompanhado do dígito 9 e o restante do número`,
  },
  "formato cep": {
    validar: (valor) => {
      let cep;
      if (valor.replaceAll) {
        cep = valor.replaceAll("-", "");
      } else {
        cep = valor.split("-").join("");
      }
      return cep.trim().match(/^\d{8}$/);
    },
    mensagem: (campo) => `Cep inválido. Deve ser um número de oito dígitos.`,
  },
  "somente numeros": {
    validar: (valor) => valor.match(/^[0-9]*$/),
    mensagem: (campo) => `O campo ${campo} deve conter somente números`,
  },
  "formato estado": {
    validar: (valor) =>
      valor.match(
        /^(AC|AL|AP|AM|BA|CE|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO|DF)$/
      ),
    mensagem: (campo) => `O campo Estado deve ser a sigla do estado escolhido.`,
  },
  "data maxima": {
    validar: (valor) => {
      const dataValor = construirData(valor);
      return dataValor <= new Date(Date.now());
    },
    mensagem: (campo) => `Data inválida.`,
  },
};

export function validarDadosCliente(dadosUsuario) {
  let campos = Object.keys(dadosUsuario).filter(
    (campo) =>
      // nao validaremos esses campos
      campo !== "nome_mae" &&
      campo !== "telefone" &&
      campo !== "data_resposta" &&
      campo !== "tipo_amostra" &&
      campo !== "cbo"
  );
  campos.forEach((campo) => {
    validarCampo(
      campo,
      dadosUsuario[campo],
      nomePorCampo[campo],
      validacoesPorCampo[campo]
    );
  });
}

// retira as mascaras dos campos cpf, celular e cep
export function formatarDadosCliente(dadosUsuario) {
  return {
    ...dadosUsuario,
    cpf: replaceAll(replaceAll(dadosUsuario.cpf, "-", ""), ".", ""),
    celular: replaceAll(
      replaceAll(replaceAll(dadosUsuario.celular, "(", ""), ")", ""),
      "-",
      ""
    ),
    cep: replaceAll(dadosUsuario.cep, "-", ""),
  };
}

export function validarSintomas(sintomas) {
  // unica validacao eh que nao se pode marcar assintomatico como nao quando nao se tem sintomas.
  const estaSintomatico = sintomas["assintomatico"] === "Nao";
  if (estaSintomatico) {
    const comSintomas = Object.keys(sintomas).some(
      (key) =>
        // o valor de outros_sintomas nao eh Sim ou Nao. Eh uma string qualquer.
        (key !== "outros_sintomas" && sintomas[key] === "Sim") ||
        (key === "outros_sintomas" && sintomas[key] !== "")
    );
    if (!comSintomas) {
      throw new Error(
        "Seu formulário diz que você não está assintomático mas você não forneceu nenhum sintoma."
      );
    }
  }
}

function validarCampo(campo, valor, nome, validacoesCampo) {
  validacoesCampo.forEach((nomeValidacao) => {
    if (!validacoes[nomeValidacao].validar(valor)) {
      throw new Error(validacoes[nomeValidacao].mensagem(nome));
    }
  });
}
