import React from "react";
import BackgroundContainer from "../../../components/BackgroundContainer/BackgroundContainer";
import Feedback from "../../../components/Feedback";

function Confirmacao() {
  return (
    <BackgroundContainer>
      <main style={{ width: "100vw", height: "100vh" }}>
        <Feedback
          title="Formulário enviado!"
          text="Nossa equipe agradece pela sua confiança!"
        />
      </main>
    </BackgroundContainer>
  );
}

export default Confirmacao;
