import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Container,
  makeStyles,
  Paper,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

import Formulario from "./Formulario";

import DNACenterLogo from "../../assets/images/logo-dna-center.png";
import validaCPF from "../../utils/validaCpf";

import api from "../../servicos/api";

const useStyles = makeStyles(() => ({
  pagina: {
    display: "flex",
    minHeight: "100vh",
    height: "auto",
    backgroundColor: "#F0F0F5",
  },
  containerLogo: {
    display: "flex",
    margin: "1.8rem 0 0 0",
    padding: "1rem",
    justifyContent: "center",
  },
  logo: {
    width: "220px",
    height: "115px",
    alignSelf: "center",
  },
  containerTela: {
    margin: "2rem 0",
  },
  tituloTela: {
    display: "flex",
    padding: "0.5rem",
    paddingBottom: "0.8rem",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
}));

export default function Agenda() {
  const classes = useStyles();
  const [cpf, setCpf] = useState("");
  const [fazerCadastro, setFazerCadastro] = useState("");
  const [dadosPaciente, setDadosPaciente] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const buscarCpf = async () => {
    try {
      setDadosPaciente({});
      if (!validaCPF(cpf)) {
        enqueueSnackbar("CPF inválido!", {
          variant: "error",
        });
        return;
      }

      const { data } = await api.get(`/v2/agenda/cliente/${cpf}`);

      if (data.cliente.length === 0) {
        setFazerCadastro(true);
      } else if (data.cliente[0]) {
        setDadosPaciente(data.cliente[0]);
        setFazerCadastro(true);
      }
    } catch (error) {
      enqueueSnackbar("Falha na busca. por favor, tente novamente.", {
        variant: "error",
      });
      return null;
    }
  };

  return (
    <div className={classes.pagina}>
      <Container maxWidth="md">
        <Paper className={classes.containerLogo}>
          <img
            src={DNACenterLogo}
            alt="Logo do DNA Center"
            className={classes.logo}
          />
        </Paper>
        <Paper className={classes.containerTela}>
          {!fazerCadastro && (
            <Paper className={classes.tituloTela}>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                style={{ textAlign: "center" }}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ maxWidth: "312px", fontSize: "1.5rem" }}
                    label="Insira o CPF"
                    value={cpf}
                    placeholder="00000000000"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        buscarCpf();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      const value = event.target.value;
                      if (!isNaN(value) && value.length <= 11) {
                        setCpf(value);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    style={{ maxWidth: "312px", marginTop: "0.3rem" }}
                    onClick={() => {
                      buscarCpf();
                    }}
                    className="send-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    id="button"
                  >
                    BUSCAR
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Paper>
        {fazerCadastro && (
          <Formulario
            buscarCpf={buscarCpf}
            setTela={setFazerCadastro}
            cpf={cpf}
            setCpf={setCpf}
            dadosPaciente={dadosPaciente}
          />
        )}
      </Container>
    </div>
  );
}
