import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DNACenterLogo from "../../../assets/images/logo-dna-center.png";
import BackgroundContainer from "../../../components/BackgroundContainer/BackgroundContainer";
import { Button } from "@material-ui/core";
import "./styles.css";
import { useEffect } from "react";

export default function ConfirmacaoEnvioRel() {
  const [codigo, setCodigo] = useState("");
  const location = useLocation();
  const copiarCodigo = () => {
    navigator.clipboard.writeText(codigo);
  };

  useEffect(() => {
    setCodigo(location?.state?.id);
  }, [location]);

  return (
    <BackgroundContainer>
      <main style={{ width: "100vw", height: "100vh" }}>
        <div className="confirmation-card">
          <div>
            <h1 style={{ marginBottom: "1rem" }}>Formulário enviado!</h1>
            <p>Agradecemos sua confiança. </p>
            <p>
              Por favor, copie o código de protocolo para suas consultas
              futuras: {codigo}
            </p>
            <Button onClick={copiarCodigo} variant="outlined" color="primary">
              Copiar
            </Button>
          </div>
          <img
            src={DNACenterLogo}
            alt="Logo do DNA Center"
            style={{ width: "240px", height: "140px", alignSelf: "center" }}
          />
        </div>
        <Link to={"/canaldaetica"} style={{ textDecoration: "none" }}>
          <Button variant="contained" color="secondary">
            Voltar
          </Button>
        </Link>
      </main>
    </BackgroundContainer>
  );
}
