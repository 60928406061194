import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    style: {
        display: "flex",
        margin: "1rem 2rem",
        width: "50%",
        minWidth: "12rem",
        "@media (max-width:700px)": {
            width: "100%",
            margin: "1rem 0",
        },
    },
}));

export default function ButtonDefault(props) {
    const classes = useStyles();
    const { action, color, children } = props;

    return (
        <Button
            className={classes.style}
            type="submit"
            variant="contained"
            color={color}
            onClick={() => action()}
        >
            {children}
        </Button>
    )
}