import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { Button, InputLabel, makeStyles, Paper } from "@material-ui/core";
import { ArrowBack, Edit, Delete, Save } from "@material-ui/icons";

import InputForm from "../Componentes/InputForm";
import SelectForm from "../Componentes/SelectForm";

import api from "../../../servicos/api";

import "./styles.css";
import ButtonDefault from "../Componentes/ButtonDefault";

const useStyles = makeStyles(() => ({
  container_form: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem 3rem",
    "@media (max-width:700px)": {
      alignItems: "center",
    },
  },
  container_item: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width:700px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  container_button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media (max-width:700px)": {
      flexDirection: "column",
      width: "100%",
    },
  },

  containerTela: {
    marginBottom: "2rem",
    padding: "2rem",
  },
}));
function diferencaDatas(d1, d2) {
  const data1 = new Date(d1);
  const data2 = new Date(d2);
  const diffTime = data1 - data2;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
function validacao(nome, email, telefone, dataNascimento, dataAgendamento) {
  const emailValidado = email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  const nomeValidado = nome.match(/^[a-z\u00C0-\u00ff A-Z]+$/);
  const telefoneValidado = telefone.match(
    // eslint-disable-next-line
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
  );

  const dataAtual = new Date();
  const dataNascimentoValidada = diferencaDatas(dataNascimento, dataAtual);
  const dataAgendamentoValidada = diferencaDatas(dataAgendamento, dataAtual);
  const result = [
    emailValidado ? true : "Email Inválido!",
    nomeValidado ? true : "Nome deve conter apenas letras!",
    telefoneValidado ? true : "Telefone inválido!",
    dataNascimentoValidada < 0
      ? true
      : "A data de nascimento tem que ser anterior a data de hoje!",
    dataAgendamentoValidada >= 0
      ? true
      : "A data de agendamento tem que ser superior ou igual a data de hoje!",
  ];
  result.forEach((element) => {
    if (element !== true) {
      throw new Error(element);
    }
  });
  return true;
}
export default function Formulario(props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { setTela, cpf, dadosPaciente, setCpf, buscarCpf } = props;

  const [vetorHora, setVetorHora] = useState([]);

  const [dataNascimento, setDataNascimento] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [id, setId] = useState("");

  const [data, setData] = useState(format(new Date(), "yyyy-MM-dd"));
  const [hora, setHora] = useState("");

  const [tipoDeTela, setTipoDeTela] = useState("AGENDAR");
  const [refreshDados, setRefreshDados] = useState(false);
  const [possuiAgendamento, setPossuiAgendamento] = useState(false);

  const cancelarCriacao = () => {
    setDataNascimento(null);
    setId("");
    setNome("");
    setEmail("");
    setTelefone("");
    setData(format(new Date(), "yyyy-MM-dd"));
    setHora("");
    setCpf("");
    setTela(false);
  };

  useEffect(() => {
    async function iniciar() {
      try {
        const result = await api.get(`/api/agendaWeb/disponibilidade/${data}`);
        setVetorHora(result.data);
      } catch (error) {
        enqueueSnackbar("Horários não carregados", { variant: "error" });
      }
    }
    iniciar();
  }, [enqueueSnackbar, data]);

  const atualizarCadastro = async () => {
    await api.put(`/v2/agenda/cadastroCliente/${dadosPaciente.id}`, {
      data_nascimento: dataNascimento,
      nome,
      email,
      telefone,
    });
    setTipoDeTela("CADASTRADO");
    enqueueSnackbar("Cadastro salvo com sucesso!", { variant: "success" });
  };

  const agendar = async (id) => {
    try {
      await api.post("/api/agendaWeb", {
        nome,
        telefone,
        data,
        hora,
        id,
      });
      cancelarCriacao();
      setTela(false);
      enqueueSnackbar("Agendamento realizado com sucesso!", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.response.data.error, {
        variant: "error",
      });
    }
  };

  const adicionarCadastro = async () => {
    const { data: result } = await api.post(`/v2/agenda/cadastroCliente`, {
      cpf,
      data_nascimento: dataNascimento,
      nome,
      email,
      telefone,
      data,
      hora,
    });
    return result;
  };

  const salvaDados = async (tipo) => {
    if (!hora && tipo !== "ATUALIZAR") {
      return enqueueSnackbar("Selecione um horário", { variant: "error" });
    }

    try {
      if (!validacao(nome, email, telefone, dataNascimento, data)) {
        return;
      }

      if (tipo === "ATUALIZAR") {
        atualizarCadastro();
      } else if (tipoDeTela === "NAO_CADASTRADO") {
        const result = await adicionarCadastro();
        const { id } = result.cadastro;
        await agendar(id);
      } else if (tipoDeTela === "CADASTRADO") {
        await agendar(id);
      }
    } catch (error) {
      enqueueSnackbar(
        error.message ||
          "Não foi possível concluir o agendamento, tente novamente",
        { variant: "error" }
      );
    }
  };

  const excluirAgendamento = async () => {
    try {
      await api.delete(`/api/agendaWeb/${data}/${id}`);
      cancelarCriacao();
      enqueueSnackbar("O agendamento foi cancelado", { variant: "success" });
    } catch (error) {
      const erroAtual = error.response.data.error;
      enqueueSnackbar(
        erroAtual || "Não foi possível excluir o agendamento, tente novamente",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (dadosPaciente.cpf) {
      setId(dadosPaciente.id);
      setDataNascimento(dadosPaciente.data_nascimento);
      setNome(dadosPaciente.nome);
      setEmail(dadosPaciente.email);
      setTelefone(dadosPaciente.telefone);
      if (dadosPaciente.hora !== null && dadosPaciente.data !== null) {
        setHora(dadosPaciente.hora);
        setData(dadosPaciente.data);
        setPossuiAgendamento(true);
      }
      setTipoDeTela("CADASTRADO");
    } else {
      setTipoDeTela("NAO_CADASTRADO");
    }
    // eslint-disable-next-line
  }, [refreshDados]);

  useEffect(() => {
    buscarCpf();
    // eslint-disable-next-line
  }, [refreshDados]);

  function cancelaEdicao() {
    setRefreshDados(!refreshDados);
    setTipoDeTela("CADASTRADO");
  }

  return (
    <div>
      {tipoDeTela === "ATUALIZAR" ? null : (
        <div className="botao-esquerda-voltar">
          <Button
            className="button_register"
            id="button"
            onClick={() => {
              cancelarCriacao();
            }}
          >
            <ArrowBack style={{ color: "blue" }} />
          </Button>
        </div>
      )}

      <Paper className={classes.containerTela}>
        <div className={classes.container_item}>
          <InputForm
            title="*CPF"
            placeholder="Informe seu cpf"
            type="number"
            value={cpf}
            readonly
          />
        </div>
        <div className={classes.container_item}>
          <InputForm
            readonly={tipoDeTela === "CADASTRADO"}
            title="*Data de Nascimento"
            placeholder="Informe sua data de nascimento"
            type="date"
            value={dataNascimento}
            action={setDataNascimento}
          />
          <InputForm
            readonly={tipoDeTela === "CADASTRADO"}
            title="*Nome"
            placeholder="Informe seu nome"
            type="text"
            value={nome}
            action={setNome}
          />
        </div>
        <div className={classes.container_item}>
          <InputForm
            readonly={tipoDeTela === "CADASTRADO"}
            title="E-mail"
            placeholder="Informe seu e-mail"
            type="email"
            value={email}
            action={setEmail}
          />
          <InputForm
            readonly={tipoDeTela === "CADASTRADO"}
            title="*Telefone"
            placeholder="(00)00000-0000"
            type="tel"
            value={telefone}
            action={setTelefone}
          />
        </div>
        {tipoDeTela === "NAO_CADASTRADO" ? null : tipoDeTela ===
          "CADASTRADO" ? (
          <div className={classes.container_button}>
            <ButtonDefault
              color="primary"
              action={() => setTipoDeTela("ATUALIZAR")}
            >
              <Edit style={{ marginRight: "0.2rem" }} /> EDITAR
            </ButtonDefault>
          </div>
        ) : (
          <div className={classes.container_item}>
            <ButtonDefault
              color="primary"
              action={() => salvaDados("ATUALIZAR")}
            >
              <Save style={{ marginRight: "0.2rem" }} /> SALVAR
            </ButtonDefault>
            <ButtonDefault color="secondary" action={cancelaEdicao}>
              <ArrowBack /> CANCELAR
            </ButtonDefault>
          </div>
        )}
      </Paper>

      <Paper
        hidden={tipoDeTela === "ATUALIZAR"}
        className={classes.containerTela}
      >
        <div className={classes.container_button}>
          <InputLabel>
            <strong>Local: </strong>DNA Center Drive II - Em frente ao Banco do
            Brasil Tirol
          </InputLabel>
        </div>
        <div className={classes.container_item}>
          <InputForm
            title="*Data"
            readonly={possuiAgendamento}
            placeholder="Informe a data desejada"
            type="date"
            value={data}
            action={setData}
          />
          <SelectForm
            title="*Horário"
            value={hora}
            action={setHora}
            readonly={possuiAgendamento}
            array={vetorHora}
          />
        </div>
        <div className={classes.container_button}>
          {possuiAgendamento ? (
            <ButtonDefault color="secondary" action={excluirAgendamento}>
              <Delete style={{ marginRight: "0.2rem" }} /> EXCLUIR
            </ButtonDefault>
          ) : (
            <ButtonDefault color="primary" action={salvaDados}>
              AGENDAR
            </ButtonDefault>
          )}
        </div>
      </Paper>
    </div>
  );
}
