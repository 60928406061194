import React, { useEffect, useState } from "react";
import api from "../../servicos/api";
import Grid from "@material-ui/core/Grid";

import semImagem from "../../assets/images/semImagem.png";

import Unidades from "./Unidades";

export default function UnidadesIndex() {
  const [unidade, setUnidade] = useState([]);
  const baseURL = process.env.REACT_APP_BASEURL_WEB;

  console.log(baseURL);

  useEffect(() => {
    async function carregarDados() {
      const responseUnidades = await api.get(`/v2/geral/unidade`);
      setUnidade(responseUnidades.data.unidade);
    }
    carregarDados();
  }, []);

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={{
        width: "98%",
        maxWidth: "1020px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {unidade.map((u) => {
        return (
          <Unidades
            key={u.id}
            unidade={u.nome}
            endereco={u.endereco}
            pontoreferencia={u.pontoreferencia}
            atendimento={u.atendimento}
            horariocoleta={u.horariocoleta}
            telefone={u.telefone}
            latitude={u.latitude}
            longitude={u.longitude}
            kids={u.kids}
            foto={u.foto ? `${baseURL}/${u.foto}` : semImagem}
          />
        );
      })}
    </Grid>
  );
}
