import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: "1rem 2rem",
    width: "50%",
    maxWidth: "360px",
    minWidth: "12rem",
    "@media (max-width:700px)": {
      width: "100%",
      maxWidth: "100%",
      margin: "1rem 0",
    },
  },
  title: {
    fontWeight: "bold",
  },
  input: {
    border: "0.1rem solid #000",
    borderRadius: "0.4rem",
    padding: "0.5rem 1rem",
    marginTop: "0.7rem",
  },
}));

export default function InputForm(props) {
  const classes = useStyles();

  const { title, readonly, placeholder, type, value, action, validation } =
    props;
  const validate = (validation, e) => {
    let newValue = e.target.value;
    console.log(newValue, validation);
  };
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <input
        className={classes.input}
        readOnly={readonly}
        style={{backgroundColor: readonly ? "#E6E6E6" : "#FFF"}}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => {
          if (!validation) {
            let newValue = e.target.value;
            if (type === "text") {
              newValue = newValue.toUpperCase();
            }
            if (action) {
              action(newValue);
            }
          } else {
            validate(validation, e);
          }
        }}
      />
    </div>
  );
}
