import React, { useState, useEffect } from 'react';
import api from '../../servicos/api';
import { Paper } from '@material-ui/core';
//import { useParams } from "react-router-dom";

//A função DetalheExame recebe o parâmetro id e filtra no banco de dados, retornando os detalhes sobre esse exame
function DetalheExame (props) {
    const [detalhe, setDetalhe] = useState([]);

    //Adcionando o id a uma variável
    //const getParams = useParams();
    //const idExame = parseInt(getParams.id);

    //Solicita a consulta no banco de dados filtrando pelo id
    useEffect(() => {
        async function carregarDetalhe(){
          const response = await api.get(`/api/detalheExame/` + props.id );
          setDetalhe(response.data);
        }
        carregarDetalhe();
    },[props.id]);
    
    
    return (
        //Exame detalhado
        <div id="detalheExame">
            {detalhe.map(det => (
                <Paper 
                    key={det.id} 
                    className="detalheItem"
                    elevation={0}>
                    <strong>{det.nome}</strong>
                    <p><b>Material:</b> {det.material}</p>
                    <p><b>Instruções:</b> {det.instrucoes}</p>
                    <p><b>Entrega:</b> {det.entrega}</p>
                </Paper>
            ))}
        </div>
    );
 
}

export default DetalheExame;