import React from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: "1rem 2rem",
    width: "50%",
    minWidth: "12rem",
    "@media (max-width:700px)": {
      width: "100%",
      margin: "1rem 0",
    },
  },
  title: {
    fontWeight: "bold",
  },
  select: {
    border: "0.1rem solid #000",
    borderRadius: "0.4rem",
    padding: "0.3rem 1rem",
    marginTop: "0.7rem",
  },
}));

export default function SelectForm(props) {
  const classes = useStyles();

  const { title, value, action, array, readonly } = props;

  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <Select
        readOnly={readonly}
        className={classes.select}
        style={{backgroundColor: readonly ? "#E6E6E6" : "#FFF"}}
        value={value}
        onChange={(e) => action(e.target.value)}
      >
        {array.map((h) => (
          <MenuItem key={h.hora} value={h.hora}>
            {h.hora}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
