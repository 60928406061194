import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  TextField,
  Grid,
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import BackgroundContainer from "../../components/BackgroundContainer/BackgroundContainer";
import LinhaFormulario from "../../components/LinhaFormulario";
import InputDate from "../../components/InputDate";
import DadosPaciente from "../../components/DadosPaciente";
import Loading from "../../components/Loading/Loading";

import "./styles.css";
import {
  nomePorSintoma,
  nomePorCondicao,
  validarDadosCliente,
  StyledCheckbox,
  validarSintomas,
  formatarDadosCliente,
} from "./util";
import dnaCenterLogo from "../../assets/images/logo-dna-center.png";
import api from "../../servicos/api";
import construirData from "../../utils/construirData";

export default function Formulario() {
  const [dados_cliente, set_dados_cliente] = useState({
    cpf: "",
    endereco: "",
    cep: "",
    endereco_numero: "",
    bairro: "",
    cidade: "",
    celular: "",
    estado: "",
    telefone: "",
    sexo: "",
    profissional_saude: "Nao",
    cbo: "",
    nome_completo: "",
    nome_mae: "",
    data_nascimento: "",
    tipo_amostra: "swab",
    data_resposta: new Date(Date.now()).toISOString(), // data de preenchimento do form
  });

  const [sintomas, set_sintomas] = useState({
    data_inicio_sintomas: new Date(), // valor inicial somente
    dor_garganta: "Nao",
    dispneia: "Nao",
    febre: "Nao",
    tosse: "Nao",
    outros_sintomas: "",
    dor_cabeca: "Nao",
    disturbios_gustativos: "Nao",
    disturbios_olfativos: "Nao",
    coriza: "Nao",
    assintomatico: "Nao",
  });

  const [condicoes_cliente, set_condicoes_cliente] = useState({
    doenca_resp: "Nao",
    doenca_card: "Nao",
    diabetes: "Nao",
    doenca_renal: "Nao",
    imunossupressao: "Nao",
    gestante: "Nao",
    doenca_cromo: "Nao",
    puerpera: "Nao",
    obesidade: "Nao",
    positivo_covid: "Nao",
    motivo: "",
    outros_motivos: "",
  });

  const [checked, setChecked] = useState(false);
  const [modoInserirDadosUsuario, setModoInserirDadosUsuario] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalido, setInvalido] = useState(false);

  const { token } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const usuario_resposta = new URLSearchParams(useLocation().search).get(
    "usuario_resposta"
  );
  useEffect(() => {
    if (sintomas.assintomatico === "Sim") {
      set_sintomas((prevState) => ({
        ...prevState,
        dor_cabeca: "Nao",
        dor_garganta: "Nao",
        febre: "Nao",
        tosse: "Nao",
        dispneia: "Nao",
        disturbios_gustativos: "Nao",
        disturbios_olfativos: "Nao",
        coriza: "Nao",
      }));
    }
  }, [sintomas.assintomatico]);

  useEffect(() => {
    (async () => {
      let form;
      let cliente;

      if (token) {
        try {
          // se o token foi fornecido, entao precisamos pegar os dados do formulario.
          const { data } = await api.get(`/api/formularioCovid/${token}`);
          form = data;
          const formularioNaoExiste = Object.keys(form).length === 0;
          if (formularioNaoExiste) {
            console.error("Formulario nao existente.");
            navigate("/formularioCovid/invalido");
            return;
          } else if (form.respondido === "SIM") {
            navigate("/formularioCovid/confirmacao");
            return;
          }

          // agora, vamos pegar os dados do cliente
          const { data: client } = await api.get(
            `https://api.dnacenter.com.br/api/softlab/cliente/${form.cpf}`
          );
          cliente = client;

          set_dados_cliente((prevState) => ({
            ...prevState,
            ...cliente,
            celular: cliente.celular || cliente.telefone,
            nome_completo: cliente.nome,
            nome_mae: cliente.mae,
          }));
        } catch (error) {
          console.error(error);
          setInvalido(true);
          enqueueSnackbar("Um erro ocorreu. Por favor, recarregue a página.", {
            variant: "error",
          });
        }
      } else {
        setModoInserirDadosUsuario(true);
        setLoading(false);
        return;
      }
      setLoading(false);
    })();
  }, [enqueueSnackbar, token, navigate]);

  const checkboxLabel = useMemo(() => {
    return `Pelo presente eu, ${dados_cliente.nome_completo}, 
    autorizo a coleta do meu material biológico para fins de realização 
    de DETECÇÃO DO CORONAVÍRUS SARS-CoV-2 (COVID-19) 
    pela técnica de PCR e a utilizar as informações para finalidades estatísticas, 
    em consonância com as determinações do Governo Federal. 
    Manifesto, de forma livre, clara e inequívoca que forneci espontaneamente as 
    informações deste formulário, estando ciente que as mesmas são imprescindíveis 
    à realização do exame objeto desta Autorização.`;
  }, [dados_cliente.nome_completo]);

  const enviarFormulario = async (event) => {
    if (
      (dados_cliente.profissional_saude === "Sim" &&
        dados_cliente.cbo !== null &&
        dados_cliente.cbo !== "") ||
      dados_cliente.profissional_saude === "Nao"
    ) {
      // validacao
      let dadosFormatadosCliente = dados_cliente;
      if (modoInserirDadosUsuario) {
        try {
          validarDadosCliente(dados_cliente);
          dadosFormatadosCliente = formatarDadosCliente(dados_cliente);
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
      }
      try {
        validarSintomas(sintomas);
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        return;
      }

      if (condicoes_cliente.motivo === "") {
        enqueueSnackbar(
          "Especifique o motivo pelo qual você está fazendo o exame",
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
        return;
      }

      if (!checked) {
        enqueueSnackbar("Concorde com os termos de envio.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        return;
      }

      const { data_inicio_sintomas, ...restoSintomas } = sintomas;

      // Converter data_inicio_sintomas para Date
      const dataInicioSintomas = new Date(data_inicio_sintomas);

      // preparar para envio do formulario
      const body = {
        ...dadosFormatadosCliente,
        ...condicoes_cliente,
        ...restoSintomas,
        dataInicioSintomas,
        data_nascimento: construirData(
          dadosFormatadosCliente.data_nascimento
        ).toISOString(),
      };

      setLoading(true);
      // temos que ver se estamos respondendo um formulario cadastrado ou nao

      // aqui nao estamos num formulario cadastrado
      let idFormulario = token;
      if (modoInserirDadosUsuario) {
        // se nao estamos, precisamos criar um formulario para depois atualiza-lo com as respostas
        try {
          const {
            data: { id },
          } = await api.post("/api/formularioCovid", {
            cliente: {
              ...dadosFormatadosCliente,
            },
          });
          idFormulario = id;
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(error.response?.data.message || error.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
      }

      //atualizar o formulario
      try {
        await api.put(`/api/formularioCovid`, {
          ...body,
          formularioId: idFormulario,
          usuario_resposta,
        });
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(
          "Ocorreu um erro ao enviar seu formulário. Caso esse erro persista, fale com nossa equipe.",
          { variant: "error", autoHideDuration: 3000 }
        );
        return;
      }
      setLoading(false);
      navigate("/formularioCovid/confirmacao");
    } else {
      setLoading(false);
      enqueueSnackbar("O campo cargo deve ser uma das opções sugeridas.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
  };

  return (
    <BackgroundContainer>
      <Container className="form-container">
        {loading && <Loading />}
        <header>
          <img src={dnaCenterLogo} alt="Logo do DNA Center" id="logo" />
          <h1 className="form-title">Formulário COVID-19</h1>
        </header>
        <section>
          <h2 className="form-subtitle">Dados do paciente</h2>
          <DadosPaciente
            dadosPaciente={dados_cliente}
            editavel={modoInserirDadosUsuario}
            setDadosPaciente={set_dados_cliente}
            setLoading={setLoading}
          />
        </section>
        <section>
          <h2 className="form-subtitle">Informações Clínicas</h2>
          {[
            "assintomatico",
            "dor_garganta",
            "dispneia",
            "febre",
            "tosse",
            "dor_cabeca",
            "disturbios_gustativos",
            "disturbios_olfativos",
            "coriza",
          ].map((sintoma) => (
            <LinhaFormulario
              key={sintoma}
              label={nomePorSintoma[sintoma]}
              value={sintomas[sintoma]}
              disabled={
                sintoma !== "assintomatico" && sintomas.assintomatico === "Sim"
              }
              onChange={(event) => {
                const value = event.target.value;
                set_sintomas((prevState) => ({
                  ...prevState,
                  [sintoma]: value,
                }));
              }}
            />
          ))}
          <TextField
            fullWidth
            label="Outros sintomas? Insira-os aqui."
            value={sintomas.outros_sintomas}
            onChange={(event) => {
              event.preventDefault();
              const value = event.target.value;
              set_sintomas((prevState) => ({
                ...prevState,
                outros_sintomas: value,
              }));
            }}
            style={{ margin: "1rem 0" }}
          />
          <InputDate
            text="Início dos sintomas"
            value={sintomas.data_inicio_sintomas}
            acao={(value) => {
              set_sintomas((prevState) => ({
                ...prevState,
                data_inicio_sintomas: value,
              }));
            }}
          />
          {Object.keys(condicoes_cliente)
            .filter(
              (condicao) =>
                condicao !== "motivo" && condicao !== "outros_motivos"
            )
            .map((condicao) => (
              <LinhaFormulario
                key={condicao}
                label={nomePorCondicao[condicao]}
                value={condicoes_cliente[condicao]}
                onChange={(event) => {
                  const value = event.target.value;
                  set_condicoes_cliente((prevState) => ({
                    ...prevState,
                    [condicao]: value,
                  }));
                }}
              />
            ))}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="section-inner linha-formulario"
          >
            <Grid item xs={6}>
              <FormLabel component="legend">
                MOTIVO PARA REALIZAÇÃO DO EXAME
              </FormLabel>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Select
                onChange={(event) => {
                  set_condicoes_cliente((prevState) => ({
                    ...prevState,
                    motivo: event.target.value,
                  }));
                }}
                value={condicoes_cliente.motivo}
                style={{
                  width: "80%",
                  maxWidth: "500px",
                  marginLeft: "auto",
                  marginTop: ".5rem",
                }}
              >
                <MenuItem value="contato">
                  CONTATO COM SUSPEITO OU POSITIVO
                </MenuItem>
                <MenuItem value="sintomatico">SINTOMÁTICO</MenuItem>
                <MenuItem value="cirurgia">CIRURGIA</MenuItem>
                <MenuItem value="viagem">VIAGEM</MenuItem>
                <MenuItem value="outros">OUTROS</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </section>
        <FormControlLabel
          style={{ display: "flex", alignItems: "flex-start" }}
          label={checkboxLabel}
          control={
            <StyledCheckbox
              checked={checked}
              onChange={(event) => {
                setChecked(!checked);
              }}
            />
          }
        />
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            onClick={enviarFormulario}
            disabled={invalido}
            className="send-button"
            style={{
              color: "white",
              fontSize: "1.4rem",
              backgroundColor: "#003399",
              marginTop: "2rem",
            }}
          >
            ENVIAR
          </Button>
        </Grid>
      </Container>
    </BackgroundContainer>
  );
}
