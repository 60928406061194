import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./styles.css";

function Loading() {
  return (
    <div className="loading-wrapper">
      <CircularProgress style={{ width: "80px", height: "80px" }} />
    </div>
  );
}

export default Loading;
