import React from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import SearchIcon from "@material-ui/icons/Search";
import nomePorCampo from "../../../utils/nomePorCampo";
import StyledRadio from "../../StyledRadio";

import CargosProfissionalSaude from "../../CargosProfissionalSaude";

const cargos = CargosProfissionalSaude();

export default function Uncontrolled({
  setDisplayProfissionalSaude,
  displayProfissionalSaude,
  editavel,
  dadosPaciente,
  setDadosPaciente,
  pesquisarCep,
  logradouroRef,
  bairroRef,
  cidadeRef,
  estadoRef,
  shrinkLabels,
  setShrinkLabels,
}) {
  try {
    return (
      <Grid
        container
        spacing={2}
        className="section-inner"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={nomePorCampo["nome_completo"]}
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              document.getElementById("nome_completo").value = value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                nome_completo: value.toUpperCase(),
              }));
            }}
            inputProps={{
              id: "nome_completo",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="99/99/9999"
            maskChar=" "
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                data_nascimento: value,
              }));
            }}
          >
            {() => (
              <TextField
                fullWidth
                label="Data de nascimento"
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="999.999.999-99"
            maskChar=" "
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                cpf: value,
              }));
            }}
          >
            {() => (
              <TextField
                fullWidth
                label="CPF"
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="sexo-select-label">Sexo</InputLabel>
            <Select
              labelId="sexo-select-label"
              fullWidth
              value={dadosPaciente.sexo}
              onChange={(event) => {
                const value = event.target.value;
                setDadosPaciente((prevState) => ({
                  ...prevState,
                  sexo: value,
                }));
              }}
            >
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Feminino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="(99)99999-9999"
            maskChar=" "
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                celular: value,
              }));
            }}
          >
            {() => (
              <TextField
                fullWidth
                label="Celular"
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={9} sm={4} md={3}>
          <InputMask
            mask="99999-999"
            maskChar=" "
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                cep: value,
              }));
            }}
          >
            {() => (
              <TextField
                fullWidth
                label="CEP"
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={3} sm={2} style={{ alignSelf: "flex-end" }}>
          <Button
            style={{ backgroundColor: "#003399", cursor: "pointer" }}
            onClick={() => pesquisarCep(dadosPaciente.cep)}
          >
            <SearchIcon fontSize="small" style={{ color: "white" }} />
          </Button>
        </Grid>
        <Grid item xs={10} sm={9} md={6} lg={5}>
          <TextField
            fullWidth
            label="Logradouro"
            InputLabelProps={{ shrink: shrinkLabels.logradouro }}
            onFocus={() =>
              setShrinkLabels((prevState) => ({
                ...prevState,
                logradouro: true,
              }))
            }
            inputRef={logradouroRef}
            inputProps={{ id: "logradouro" }}
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              logradouroRef.current.value = value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                endereco: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <TextField
            fullWidth
            label="Número"
            inputProps={{ id: "endereco_numero" }}
            onBlur={(event) => {
              const value = event.target.value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                endereco_numero: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Bairro"
            InputLabelProps={{ shrink: shrinkLabels.bairro }}
            inputProps={{ id: "bairro" }}
            inputRef={bairroRef}
            onFocus={() =>
              setShrinkLabels((prevState) => ({
                ...prevState,
                bairro: true,
              }))
            }
            onChange={(event) => {
              const value = event.target.value;
              event.target.value = value.toUpperCase();
            }}
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                bairro: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Cidade"
            InputLabelProps={{ shrink: shrinkLabels.cidade }}
            inputProps={{ id: "cidade" }}
            inputRef={cidadeRef}
            onFocus={() =>
              setShrinkLabels((prevState) => ({
                ...prevState,
                cidade: true,
              }))
            }
            onChange={(event) => {
              const value = event.target.value;
              event.target.value = value.toUpperCase();
            }}
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                cidade: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="UF"
            InputLabelProps={{ shrink: shrinkLabels.estado }}
            inputProps={{ id: "estado" }}
            inputRef={estadoRef}
            onFocus={() =>
              setShrinkLabels((prevState) => ({
                ...prevState,
                estado: true,
              }))
            }
            onChange={(event) => {
              const value = event.target.value;
              event.target.value = value.toUpperCase();
            }}
            onBlur={(event) => {
              const value = event.target.value.toUpperCase();
              setDadosPaciente((prevState) => ({
                ...prevState,
                estado: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <label style={{ marginRight: "8px" }}>
            <b>Profissional de saúde?</b>
          </label>
          <RadioGroup
            row
            value={dadosPaciente.profissional_saude}
            onChange={(event, newValue) => {
              const value = event.target.value;
              if (value === "Sim") {
                setDisplayProfissionalSaude(false);
              } else {
                setDisplayProfissionalSaude(true);
                setDadosPaciente((prevState) => ({
                  ...prevState,
                  cbo: "",
                }));
              }
              setDadosPaciente((prevState) => ({
                ...prevState,
                profissional_saude: newValue,
              }));
            }}
          >
            <FormControlLabel
              label="Não"
              value="Nao"
              control={<StyledRadio />}
            />
            <FormControlLabel
              label="Sim"
              value="Sim"
              control={<StyledRadio />}
            />
          </RadioGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          id={
            displayProfissionalSaude
              ? "some_display_select"
              : "mostra_display_select"
          }
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Cargo
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            fullWidth
            value={dadosPaciente.cbo}
            onChange={(event, newValue) => {
              let value = event.target.value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                cbo: value,
              }));
            }}
            displayEmpty
          >
            {cargos.map((nome) => (
              <MenuItem key={nome} value={nome}>
                {nome.split(" - ")[1]}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <label style={{ marginRight: "8px" }}>
            <b>Tipo de amostra?</b>
          </label>
          <RadioGroup
            row
            value={dadosPaciente.tipo_amostra}
            onChange={(event) => {
              const value = event.target.value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                tipo_amostra: value,
              }));
            }}
          >
            <FormControlLabel
              label="Swab nasal"
              value="swab"
              control={<StyledRadio />}
            />
            <FormControlLabel
              label="Outra"
              value="outra"
              control={<StyledRadio />}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    );
  } catch (error) {
    console.log(error);
  }
}
