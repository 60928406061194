import React, { useState, useRef } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";

import StyledRadio from "../StyledRadio";

import "./styles.css";

import nomePorCampo from "../../utils/nomePorCampo";
import Uncontrolled from "./Uncontrolled";
import CargosProfissionalSaude from "../CargosProfissionalSaude";

const cargos = CargosProfissionalSaude();
export default function DadosPaciente({
  editavel,
  dadosPaciente,
  setDadosPaciente,
  setLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  /* Como estamos usando componentes nao controlados para inputs, precisamos controlar 
  a aparencia das InputLabels desses campos manualmente. 
  Isso soluciona o problema das labels desses campos nao subirem quando pesquisamos o CEP*/
  const [shrinkLabels, setShrinkLabels] = useState({
    logradouro: false,
    bairro: false,
    cidade: false,
    estado: false,
  });
  const [displayProfissionalSaude, setDisplayProfissionalSaude] = useState(
    true
  );
  // refs para setar o valor manualmente quando pesquisamos o cep
  const logradouroRef = useRef(null);
  const bairroRef = useRef(null);
  const cidadeRef = useRef(null);
  const estadoRef = useRef(null);
  const pesquisarCep = async (cep) => {
    setLoading(true);
    let endereco;
    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      endereco = data;
      if (data.erro) {
        throw new Error("Cep invalido");
      }
    } catch (e) {
      enqueueSnackbar("Cep inválido", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setDadosPaciente((prevState) => ({
        ...prevState,
        bairro: "",
        cidade: "",
        estado: "",
        endereco: "",
      }));
      setLoading(false);
      return;
    }
    setLoading(false);
    setDadosPaciente((prevState) => ({
      ...prevState,
      bairro: endereco.bairro.toUpperCase(),
      cidade: endereco.localidade.toUpperCase(),
      estado: endereco.uf.toUpperCase(),
      endereco: endereco.logradouro.toUpperCase(),
    }));
    logradouroRef.current.value = endereco.logradouro.toUpperCase();
    bairroRef.current.value = endereco.bairro.toUpperCase();
    cidadeRef.current.value = endereco.localidade.toUpperCase();
    estadoRef.current.value = endereco.uf.toUpperCase();
    setShrinkLabels({
      logradouro: true,
      cidade: true,
      bairro: true,
      estado: true,
    });
    return;
  };

  if (editavel) {
    return (
      <Uncontrolled
        editavel={editavel}
        dadosPaciente={dadosPaciente}
        setDadosPaciente={setDadosPaciente}
        setDisplayProfissionalSaude={setDisplayProfissionalSaude}
        displayProfissionalSaude={displayProfissionalSaude}
        pesquisarCep={pesquisarCep}
        logradouroRef={logradouroRef}
        bairroRef={bairroRef}
        cidadeRef={cidadeRef}
        estadoRef={estadoRef}
        shrinkLabels={shrinkLabels}
        setShrinkLabels={setShrinkLabels}
      />
    );
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        className="section-inner"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            disabled={!editavel}
            label={nomePorCampo["nome_completo"]}
            value={dadosPaciente.nome_completo}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="99/99/9999"
            maskChar=" "
            disabled={!editavel}
            value={dadosPaciente.data_nascimento}
          >
            {() => (
              <TextField
                fullWidth
                label="Data de nascimento"
                disabled={!editavel}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="999.999.999-99"
            maskChar=" "
            disabled={!editavel}
            value={dadosPaciente.cpf}
          >
            {() => <TextField fullWidth label="CPF" disabled={!editavel} />}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="sexo-select-label">Sexo</InputLabel>
            <Select
              disabled={!editavel}
              labelId="sexo-select-label"
              fullWidth
              value={dadosPaciente.sexo}
            >
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Feminino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <InputMask
            mask="(99)99999-9999"
            maskChar=" "
            disabled={!editavel}
            value={dadosPaciente.celular || dadosPaciente.telefone}
          >
            {() => <TextField fullWidth label="Celular" disabled={!editavel} />}
          </InputMask>
        </Grid>
        <Grid item xs={9} sm={4} md={3}>
          <InputMask
            mask="99999-999"
            maskChar=" "
            disabled={!editavel}
            value={dadosPaciente.cep}
          >
            {() => <TextField fullWidth label="CEP" disabled={!editavel} />}
          </InputMask>
        </Grid>
        <Grid item xs={3} sm={2} style={{ alignSelf: "flex-end" }}>
          <Button
            disabled={!editavel}
            style={{ backgroundColor: "#003399", cursor: "pointer" }}
          >
            <SearchIcon fontSize="small" style={{ color: "white" }} />
          </Button>
        </Grid>
        <Grid item xs={10} sm={9} md={6} lg={5}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Logradouro"
            value={dadosPaciente.endereco}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Número"
            id="numero"
            value={dadosPaciente.endereco_numero}
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Bairro"
            id="bairro"
            value={dadosPaciente.bairro}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="Cidade"
            id="cidade"
            value={dadosPaciente.cidade}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <TextField
            fullWidth
            disabled={!editavel}
            label="UF"
            id="estado"
            value={dadosPaciente.estado}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <label style={{ marginRight: "8px" }}>
            <b>Profissional de saúde?</b>
          </label>
          <RadioGroup
            row
            value={dadosPaciente.profissional_saude}
            onChange={(event, newValue) => {
              const value = event.target.value;
              if (value === "Sim") {
                setDisplayProfissionalSaude(false);
              } else {
                setDisplayProfissionalSaude(true);
                setDadosPaciente((prevState) => ({
                  ...prevState,
                  cbo: "",
                }));
              }
              setDadosPaciente((prevState) => ({
                ...prevState,
                profissional_saude: newValue,
              }));
            }}
          >
            <FormControlLabel
              label="Não"
              value="Nao"
              control={<StyledRadio />}
            />
            <FormControlLabel
              label="Sim"
              value="Sim"
              control={<StyledRadio />}
            />
          </RadioGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          id={
            displayProfissionalSaude
              ? "some_display_select"
              : "mostra_display_select"
          }
        >
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            Cargo
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            fullWidth
            value={dadosPaciente.cbo}
            onChange={(event, newValue) => {
              let value = event.target.value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                cbo: value,
              }));
            }}
            displayEmpty
          >
            {cargos.map((nome) => (
              <MenuItem key={nome} value={nome}>
                {nome.split(" - ")[1]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <label style={{ marginRight: "8px" }}>
            <b>Tipo de amostra?</b>
          </label>
          <RadioGroup
            row
            value={dadosPaciente.tipo_amostra}
            onChange={(event) => {
              const value = event.target.value;
              setDadosPaciente((prevState) => ({
                ...prevState,
                tipo_amostra: value,
              }));
            }}
          >
            <FormControlLabel
              label="Swab nasal"
              value="swab"
              control={<StyledRadio />}
            />
            <FormControlLabel
              label="Outra"
              value="outra"
              control={<StyledRadio />}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
}
