import React, { useState, useRef } from "react";
import { Grid, TextField, Button, Card } from "@material-ui/core";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";

import BackgroundContainer from "../../components/BackgroundContainer/BackgroundContainer";
import Loading from "../../components/Loading/Loading";
import Feedback from "../../components/Feedback";

import validateCpf from "../../validations/cpf";
import checkNotEmpty from "../../validations/notEmpty";
import assert from "../../utils/assert";
import replaceAll from "../../utils/replaceAll";
import api from "../../servicos/api";

import "./styles.css";

const typeOfDataOwner = [
  "Candidato(a) ao emprego",
  "Estudante",
  "Cliente",
  "Funcionário(a)/Ex funcionário",
  "Fornecedor",
  "Profissional de saúde",
  "Responsável legal",
];

const requestTypes = [
  "Confirmação da existência de tratamento de dados pessoais",
  "Acesso aos dados pessoais tratados",
  "Recebimento de cópia completa de dados pessoais",
  "Informação sobre o uso compartilhado de dados pessoais",
  "Revogação de consentimento",
  "Retificação de dados pessoais",
  "Portabilidade de dados pessoais",
  "Revisão de decisões automatizadas",
  "Anonimização, bloqueio, eliminação de dados pessoais",
  "Oposição à atividade de tratamento de dados pessoais",
];

function OptionGrid({ options, setValue, isSelected }) {
  return (
    <div className="option-grid">
      {options.map((option) => (
        <Card
          className={`option ${isSelected(option) ? "selected" : null}`}
          onClick={() => setValue(option)}
          key={`option-${option}`}
        >
          {option}
        </Card>
      ))}
    </div>
  );
}

export default function PortalPrivacidade() {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const cpfRef = useRef(null);
  const phoneRef = useRef(null);
  const descriptionRef = useRef(null);

  const [requests, setRequests] = useState([]);
  const [userType, setUserType] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const fileInputRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const onFileSpanClick = () => {
    if (file) {
      setFile(null);
      fileInputRef.current.value = null;
    } else {
      fileInputRef.current.click();
    }
  };

  const onRequestClick = (request) => {
    const copy = [...requests];
    const index = copy.findIndex((r) => r === request);
    if (index === -1) {
      copy.push(request);
    } else {
      copy.splice(index, 1);
    }
    setRequests(copy);
  };

  const validateForm = (firstName, lastName, email, cpf, phone) => {
    assert(checkNotEmpty(firstName), "Forneça seu primeiro nome!");
    assert(checkNotEmpty(lastName), "Forneça seu último nome!");
    assert(checkNotEmpty(email), "O campo email não pode ser vazio!");
    assert(checkNotEmpty(cpf), "Forneça um CPF válido!");
    assert(validateCpf(cpf), "CPF inválido!");
    assert(checkNotEmpty(phone), "Forneça um número para contato!");
    assert(userType !== "", "Escolha sua categoria de titular de dados!");
    assert(requests.length > 0, "Escolha pelo menos um tipo de solicitação!");
    assert(
      file !== null,
      "É obrigatório fornecer um documento como prova de identidade!"
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const cpf = replaceAll(
      replaceAll(replaceAll(cpfRef.current.value, ".", ""), "-", ""),
      "_",
      ""
    );
    const phone = replaceAll(
      replaceAll(
        replaceAll(replaceAll(phoneRef.current.value, "(", ""), ")", ""),
        "-",
        ""
      ),
      "_",
      ""
    );
    const description = descriptionRef.current.value;

    // first, validate the form
    try {
      validateForm(firstName, lastName, email, cpf, phone);
    } catch (e) {
      return enqueueSnackbar(e.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

    setLoading(true);
    // second, validate the recaptcha
    const grecaptcha = window.grecaptcha;
    grecaptcha.ready(async function () {
      const token = await grecaptcha.execute(
        "6Led54ccAAAAAG6-SHdz5hzV8w3D_XB8ITHLYFSK",
        { action: "submit" }
      );
      try {
        const {
          data: { success },
        } = await api.post("/v2/portalPrivacidade/recaptcha", {
          token,
        });
        // if everything went ok with recaptcha, then send the data somewhere
        if (success) {
          setLoading(false);
          setFormSent(true);
          const formData = new FormData();
          formData.append("firstName", firstName);
          formData.append("lastName", lastName);
          formData.append("cpf", cpf);
          formData.append("email", email);
          formData.append("phone", phone);
          formData.append("description", description);
          formData.append("userType", userType);
          formData.append("requests", JSON.stringify(requests));
          formData.append("attachment", file);
          await api.post("/v2/portalPrivacidade/sendMail", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          enqueueSnackbar("Você falhou na validação reCAPTCHA.", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setLoading(false);
        }
      } catch (e) {
        enqueueSnackbar("Ocorreu um erro ao tentar enviar seus dados.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
      }
    });
  };

  return (
    <BackgroundContainer>
      {loading && <Loading />}
      <main className="portal-privacidade">
        {formSent ? (
          <Feedback
            title="Formulário enviado!"
            text="Seus dados foram enviados com sucesso. Nossa equipe entrará em contato com você em breve."
          />
        ) : (
          <form className="form" onSubmit={onSubmit}>
            <h1>Portal da Privacidade</h1>
            <div className="paragraphs">
              <p>
                Para melhor atendermos, disponibilizamos esse campo de
                informações sobre o tratamento de seus dados pessoais.{" "}
              </p>
              <p>
                Ressaltamos que, de acordo com o art. 41 da LGPD, o Encarregado
                de Proteção de Dados ou o DPO (Data Protection Officer) é o
                indicado pelo Controlador para atuar na comunicação entre o
                Controlador, os titulares dos dados e a Autoridade Nacional de
                Proteção de Dados (ANPD).
              </p>
              <p>
                O profissional responsável por exercer esta função no Grupo DNA
                é:
              </p>
              <p>
                <b>Nome:</b> JACKSON DA CRUZ GERÔNIMO DA SILVA
              </p>
              <p>
                <b>E-Mail:</b> dpo@dnacenter.com.br
              </p>
              <p>
                Para melhor esclarecer, dentre as principais responsabilidades e
                atribuições do Encarregado, tem-se:
              </p>
              <p>
                I - Aceitar reclamações e comunicações dos titulares, prestar
                esclarecimentos e adotar providências;
                <br />
                II - Receber comunicações da autoridade nacional (ANPD) e adotar
                providências;
                <br />
                III - Orientar os funcionários e os contratados da entidade a
                respeito das práticas a serem tomadas em relação à proteção de
                dados pessoais; e
                <br />
                IV - Executar as demais atribuições determinadas pelo
                controlador ou estabelecidas em normas complementares.
              </p>
              <p>
                Para realizar uma requisição, utilize nosso o e-mail acima ou
                através do box abaixo.
              </p>
              <p>
                Responderemos suas solicitações em até 15 (quinze) dias úteis, a
                partir da data de seu requerimento.
              </p>
              <p>
                Toda e qualquer solicitação sobre os seus dados pessoais é
                gratuita.
              </p>
            </div>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <TextField label="Nome" fullWidth inputRef={firstNameRef} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Sobrenome" fullWidth inputRef={lastNameRef} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email" fullWidth inputRef={emailRef} />
              </Grid>
              <Grid item xs={6}>
                <InputMask mask="999.999.999-99">
                  {() => <TextField label="CPF" fullWidth inputRef={cpfRef} />}
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <InputMask mask="(99)99999-9999">
                  {() => (
                    <TextField label="Celular" fullWidth inputRef={phoneRef} />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <h2>Categoria de titular de dados</h2>
              </Grid>
              <Grid item xs={12}>
                <OptionGrid
                  options={typeOfDataOwner}
                  setValue={setUserType}
                  isSelected={(op) => op === userType}
                />
              </Grid>
              <Grid item xs={12}>
                <h2>
                  Selecione o(s) tipo(s) de solicitação(ões) que você deseja
                  realizar
                </h2>
              </Grid>
              <Grid item xs={12}>
                <OptionGrid
                  options={requestTypes}
                  setValue={onRequestClick}
                  isSelected={(op) =>
                    requests.findIndex((o) => op === o) !== -1
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Descrição da solicitação"
                  placeholder="Dê detalhes a mais sobre sua solicitação"
                  multiline
                  minRows={4}
                  fullWidth
                  inputRef={descriptionRef}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  hidden
                  accept=".pdf,image/*"
                  ref={fileInputRef}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <span className="file-span" onClick={onFileSpanClick}>
                  {file ? `Remover anexo (${file.name})` : "Anexar arquivo"}
                </span>
                <span className="file-about-span">
                  Anexe um documento oficial com foto (imagem ou PDF) para que
                  possamos comprovar sua identidade
                </span>
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#003399", color: "white" }}
            >
              Enviar
            </Button>
          </form>
        )}
      </main>
    </BackgroundContainer>
  );
}
